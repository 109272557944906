<template>
<el-dialog title=""
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          :modal='true'
          width="900px"
          custom-class="guideDialog"
          @open="initData"
          :destroy-on-close="true"
          :show-close="false">
  <div slot="title">
    <span v-if="neverShowBtn" style="float: left;margin-top: -20px;font-size: 14px;cursor: pointer;color:#fff;" @click="neverShow">下次不再弹出</span>
    <span style="float: right;margin-top: -20px;font-size: 14px;" class="close-x" @click="handleClose"></span>
  </div>
  <video id="guidevideo"
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-button vjs-big-play-centered my_video">
    <source :src="video"
            type="video/mp4">
  </video>
</el-dialog>
</template>

<script>
import videojs from "video.js"
import { closeIntroVideo  } from '@/api/home.js'

export default {
  data () {
    return {
      dialogVisible: false,
      neverShowBtn: true,
      video: '',
      player: null,
      videoOptions: {
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 是否静音,默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true,
        height: "550px",
        fluid: true,
        sources: [
          {
            // src: "https://sunraydtk.oss-cn-beijing.aliyuncs.com/ddup_help/%E9%AB%98%E4%B8%AD.mp4",
            src: "https://sunraydtk.oss-cn-beijing.aliyuncs.com/ddup_help/%E9%AB%98%E4%B8%AD%E5%8E%8B%E7%BC%A9.mp4",
            type: "video/mp4",
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

    }
  },
  created () {
  },
  methods: {
    initData () {
      setTimeout(() => {
        // this.video = this.$route.query.video
        this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady () {
          this.play()
          // console.log('onPlayerReady', this);
        })
        // 禁用右键菜单
        this.$refs.videoPlayer.addEventListener('contextmenu', function (event) {
          event.preventDefault();
        });
      }, 500);
    },
    handleClose () {
      this.player.dispose()
      this.dialogVisible = false
    },
    neverShow () {
      closeIntroVideo().then(res => {
        if (res.status == 200) {
          var userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
          userInfo.is_show_introduce = 0
          window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
          this.handleClose()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.my_video {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: 0 auto;
}
.close-x {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.close-x:hover::before,
.close-x:hover::after {
  background: #fff;
  cursor: pointer;
}
.close-x::before,
.close-x::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
}
.close-x::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-x::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
</style>